import React from "react"
import {Helmet} from "react-helmet";

const About = () => (
  <main>
  <Helmet><meta http-equiv="refresh" content="0; URL='/why-ecomtrack/'" /></Helmet>
  </main>
);

export default About;
